import React from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "../../Components/RequireAuth/RequireAuth";
import ViewTable from "./ViewTable/ViewTable";
import ShowUser from "./ShowUser/ShowUser";
import ShowProduct from "./Products/ShowProduct/ShowProduct";
import EditProduct from "./Products/EditProduct";
import AddProduct from "./Products/AddProduct";
import NestedList from "../../Utilities/NestedFolder/NestedList";
import AddAccount from "./AddAccount/AddAccount";
import EditUser from "./EditUser/EditUser";
import ShowCategory from "./Category/ShowCategory";
import EditCategory from "./Category/EditCategory";
import AddCategory from "./Category/AddCategory";
import SideBar from "./SideBar/SideBar";
import { Container, Row, Col } from "react-bootstrap";
import PresistLogin from "../../Components/PresistLogin/PresistLogin";

const AdminLayout = () => {
	return (
		<Container fluid>
			<Row>
				<Col xs={2} className="p-0">
					<SideBar />
				</Col>
				<Col xs={10} className="mt-5 px-2">
					<Routes>
						<Route element={<PresistLogin />}>
							<Route element={<RequireAuth allowedRoles={"1"} />}>
								<Route path="/Admin/ViewTables/:type" element={<ViewTable />} />
								<Route path="/Admin/Showusers/:id" element={<ShowUser />} />
								<Route
									path="/Admin/Showproducts/:id"
									element={<ShowProduct />}
								/>
								<Route
									path="/Admin/Editproducts/:id"
									element={<EditProduct />}
								/>
								<Route path="/Admin/Addproducts" element={<AddProduct />} />
								<Route path="/Admin/NestedList" element={<NestedList />} />
								<Route path="/Admin/Addusers" element={<AddAccount />} />
								<Route path="/Admin/Editusers/:id" element={<EditUser />} />

								<Route
									path="/Admin/Showcategories/:id"
									element={<ShowCategory />}
								/>
								<Route
									path="/Admin/Editcategories/:id"
									element={<EditCategory />}
								/>
								<Route path="/Admin/Addcategories" element={<AddCategory />} />
							</Route>
						</Route>
					</Routes>
				</Col>
			</Row>
		</Container>
	);
};

export default AdminLayout;
