import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import CategoryTable from "./Components/CategoryTable/CategoryTable";
import ContactUs from "./Pages/User/ContactUs/ContactUs";
import ShoppingCart from "./Components/ShoppingCart/ShoppingCart";
import FAQ from "./Pages/User/FAQ/FAQ";
import { Button, Container } from "react-bootstrap";
import ProductDetail from "./Pages/User/Product detail/ProductDetail";
import ForgatPassword from "./Pages/User/ForgotPassword/ForgotPassword";
import { useContext, useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import CreateAccount from "./Pages/User/Account/CreateAccount";
import { ResetPassword } from "./Pages/User/ResetPassword/ResetPassword";
import { AuthInfoContext } from "./Store/AuthInfoContext";
import Layout from "./Components/Layout/Layout";
import AdminLayout from "./Pages/Admin/AdminLayout";
import Products from "./Pages/User/Products/Products";
import UseRefreshToken from "./Utilities/UseRefreshToken/UseRefreshToken";
import PresistLogin from "./Components/PresistLogin/PresistLogin";
import PaymentStripe from "./Components/PaymentStripe/PaymentStripe";
import { FilterDataContext } from "./Store/FilterDataContext";

function App() {
	const [isAdmin, setisAdmin] = useState(false);
	const [url, seturl] = useState(
		"https://backend.aromacbdhb.de/api/home/get-data"
	);
	const [Categories, setCategories] = useState(null);
	const [products, setProducts] = useState(null);
	const { AuthInfo } = useContext(AuthInfoContext);
	const { FilterData } = useContext(FilterDataContext);
	const refresh = UseRefreshToken();

	const fetchData = async () => {
		try {
			const response = await axios.get(url);
			console.log(response);
			setCategories(response.data.categories);
			setProducts(response.data.products);
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
	useEffect(() => {
		AuthInfo?.user?.admin === 1 ? setisAdmin(true) : setisAdmin(false);
	}, [AuthInfo, isAdmin]);

	useEffect(() => {
		fetchData();
	}, [url]);

	useEffect(() => {
		refresh();
	}, []);

	useEffect(() => {
		seturl(
			`https://backend.aromacbdhb.de/api/home/get-data?category=${FilterData}`
		);
	}, [FilterData]);

	return (
		<div className="App">
			{!isAdmin && <Header />}

			{!isAdmin ? (
				<div className={(isAdmin && "Home") || "Home pt-5"}>
					<>
						<div className="categories ">
							<CategoryTable />
							{Categories?.map((category) => (
								<CategoryTable data={category} key={category.id} />
							))}
						</div>
						<Container
							className={(isAdmin && "Content pt-4") || "Content px-5 mt-3"}>
							<Routes>
								<Route path="/" element={<Layout />}>
									<Route element={<PresistLogin />}>
										{/* User Routes */}
										<Route path="/paymant" element={<PaymentStripe />} />
										<Route path="/" element={<Products data={products} />} />
										<Route
											path="/ProductDetail/:id"
											element={<ProductDetail />}
										/>
										<Route path="/ShoppingCart" element={<ShoppingCart />} />
										<Route path="/ContactUs" element={<ContactUs />} />
										<Route path="/FAQ" element={<FAQ />} />
										<Route path="/CreateAccount" element={<CreateAccount />} />
										<Route
											path="/forgotPassword"
											element={<ForgatPassword />}
										/>
										<Route path="/ResetPassword" element={<ResetPassword />} />
									</Route>
								</Route>
							</Routes>
						</Container>
					</>
				</div>
			) : (
				<AdminLayout />
			)}

			{!isAdmin && <Footer data={Categories} />}
		</div>
	);
}

export default App;
