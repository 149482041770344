import ListGroup from "react-bootstrap/ListGroup";
import "./ShowUser.css";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";
import { useParams } from "react-router-dom";

const ShowUser = () => {
	const { id } = useParams();
	const { data, loading, error } = useAxiosGet(`/dashboard/users/${id}`, true);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;
	if (!data) return null;

	const user = data.user;

	return (
		<div className="ShowUser">
			<h1 className="fs-1" style={{ color: "gray" }}>
				{user.fname} Information
			</h1>
			<ListGroup>
				<ListGroup.Item action variant="secondary">
					ID:
					<ListGroup.Item action variant="light">
						{user.id}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					First Name:
					<ListGroup.Item action variant="light">
						{user.fname}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					Last Name:
					<ListGroup.Item action variant="light">
						{user.lname}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					Address 1:
					<ListGroup.Item action variant="light">
						{user.address_1}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					Address 2:
					<ListGroup.Item action variant="light">
						{user.address_2}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					Date of Birth:
					<ListGroup.Item action variant="light">
						{user.date_of_birth}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					City:
					<ListGroup.Item action variant="light">
						{user.city}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					Country:
					<ListGroup.Item action variant="light">
						{user.country}
					</ListGroup.Item>
				</ListGroup.Item>
				<ListGroup.Item action variant="secondary">
					Email:
					<ListGroup.Item action variant="light">
						{user.email}
					</ListGroup.Item>
				</ListGroup.Item>
			</ListGroup>
		</div>
	);
};

export default ShowUser;
