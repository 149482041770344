import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter } from "react-router-dom";
// import { UseShoppingCartContext } from './Store/ShoppingCartContext';
import { UseAuthInfoContext } from "./Store/AuthInfoContext";
import { UseFilterDataContext } from "./Store/FilterDataContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <UseAuthInfoContext>
      <UseFilterDataContext>
        <App />
      </UseFilterDataContext>
    </UseAuthInfoContext>
  </HashRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
