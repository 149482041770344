import Table from "react-bootstrap/Table";
import "./ShoppingCart.css";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import useAxiosGet from "../../Utilities/UseAxiosGet/UseAxiosGet";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import useAxiosDelete from "../../Utilities/UseAxiosDelete/UseAxiosDelete";
import { useNavigate } from "react-router-dom";
const ShoppingCart = () => {
	const navigate = useNavigate();
	const [CartData, setCartData] = useState([]);
	const [totalPrice, setTotalPrice] = useState(0);
	const { data } = useAxiosGet("/api/orders", true);
	const { deleteData } = useAxiosDelete();
	const { AuthInfo } = useContext(AuthInfoContext);
	useEffect(() => {
		setCartData(data?.orders);
	}, [data]);

	useEffect(() => {

		if (CartData && CartData.length > 0) {
			const totalPrice = CartData.reduce(
				(acc, curr) => acc + parseFloat(curr.total),
				0
			);
			setTotalPrice(totalPrice);
		}
	}, [CartData]);
	return (
		<div className="ShoppingCart m-1 mx-3">
			{CartData?.length === 0 ? (
				<div className="emptyCart p-3 fs-4">
					{" "}
					Current ShoppingCart <br />
					there is no data to print ={">"}{" "}
					<Link to="/" style={{ color: "white", textDecoration: "none" }}>
						{" "}
						go buy some
					</Link>
				</div>
			) : (
				<div>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Delete</th>
								<th>Name</th>
								<th>Price</th>
								<th>Amount</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							{CartData?.map((product) => (
								<tr key={product.id}>
									<td
										onClick={() => {
											deleteData(`api/orders/${product.id}`);
											// ChangeCartInfo({...product, quantity: -1})
										}}
										style={{ color: "red", cursor: "pointer" }}
										className="px-4">
										X
									</td>
									<td>{product?.product.name}</td>
									<td>€ {product?.product.price}</td>
									<td>{product?.quantity}</td>
									<td>€ {product?.total}</td>
								</tr>
							))}
						</tbody>
					</Table>
					<div className="CartButtons">
						<p className="py-1 px-3 fs-5">Total Price: € {totalPrice} </p>
						<Button variant="outline-dark" onClick={() => navigate("/paymant")}>
							{" "}
							check out
						</Button>
					</div>
				</div>
			)}
		</div>
	);
};

export default ShoppingCart;
