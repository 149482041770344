import { React, useContext, useEffect, useState } from "react";
import "./ViewTable.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FaEdit, FaTrash, FaInfoCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { AuthInfoContext } from "../../../Store/AuthInfoContext";
import axios from "axios";
import useAxiosDelete from "../../../Utilities/UseAxiosDelete/UseAxiosDelete";
import { Button } from "react-bootstrap";
import useAxiosPut from "../../../Utilities/UseAxiosPut/UseAxiosPut";
const ViewTable = () => {
  const { putData } = useAxiosPut();
  const [TableData, setTableData] = useState([]);
  const { deleteData } = useAxiosDelete();
  const { AuthInfo ,ChangeAuthInfo} = useContext(AuthInfoContext);
  const token = AuthInfo?.user?.token;
  const navigate = useNavigate();
  const params = useParams();
  const [url, setUrl] = useState(
    `https://backend.aromacbdhb.de/dashboard/${params.type}`
  );
  useEffect(() => {
    let url = `https://backend.aromacbdhb.de/dashboard/${params.type}`;
    setUrl(url);
    fetchData(url, token);
  }, [params]);
  const fetchData = async (url, token) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response.data);
      setTableData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error?.response?.status === 401) {
        ChangeAuthInfo({})
        navigate("/CreateAccount");

      }
    }
  };

	const handleInfo = (id) => {
		navigate("/Admin/Show" + params.type + "/" + id);
	};

	const handleDelete = (id) => {
		console.log(url + "/" + id);
		deleteData(url + "/" + id);
	};

	const handleEdit = (id) => {
		params.type === "orders"
			? putData(`${url}/${id}?status=delivered`, {})
			: navigate("/Admin/Edit" + params.type + "/" + id);
	};

	const actionsFormatter = (cell, row) => {
		return (
			<div className="DashboardActions">
				{params.type === "contact-us" ? (
					<></>
				) : (
					<FaEdit onClick={() => handleEdit(row.order?.id || row.id)} />
				)}
				{params.type === "contact-us" || params.type === "orders" ? (
					<></>
				) : (
					<FaInfoCircle onClick={() => handleInfo(row.order?.id || row.id)} />
				)}
				<FaTrash onClick={() => handleDelete(row.order?.id || row.id)} />
			</div>
		);
	};
	// Dynamic Columns
	let TableColumns = [];
	if (TableData.length > 0) {
		const firstItem = TableData[0];
		const keys = Object.keys(firstItem);

		// Check if the first item has nested objects
		const hasNestedObjects = keys.some(
			(key) => typeof firstItem[key] === "object" && firstItem[key] !== null
		);
		const dataObject = TableData[0].order || TableData[0]; // Use the "order" object if available, otherwise use the main object

		TableColumns = Object.keys(dataObject)
			.filter((key) => key !== "image_url") // Filter out the "image_url" key
			.map((key) => ({
				dataField: hasNestedObjects ? `order.${key}` : key,
				text: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter of the key for display
			}));
		TableColumns.push({
			dataField: "actions",
			text: "Actions",
			formatter: actionsFormatter,
		});
	}

	return (
		<div className="DashboardTable table-responsive ">
			{params.type === "contact-us" || params.type === "orders" ? (
				<></>
			) : (
				<Button
					variant="dark"
					className="m-3"
					onClick={() => navigate("/Admin/Add" + params.type)}>
					{" "}
					Add {params.type}{" "}
				</Button>
			)}
			{TableData && TableData.length > 0 ? (
				<BootstrapTable
					keyField="id"
					data={TableData}
					columns={TableColumns}
					pagination={paginationFactory()}
					hover
					striped
					condensed
				/>
			) : (
				<p>No data available</p>
			)}
		</div>
	);
};

export default ViewTable;
