import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
const ProductDetail = () => {
  const { id } = useParams();
  const { data } = useAxiosGet(`/api/home/products/${id}`);
  const [quantity, setQuantity] = useState(1);
  const { postData } = useAxiosPost();
  const [productData, setProductData] = useState({
    title: "",
    description: "",
    price: 0,
    image_url: "",
  });

  // Update productData when data changes
  useEffect(() => {
    if (data && data.product) {
      setProductData({
        title: data.product.name,
        description: data.product.description,
        price: data.product.price,
        image_url: data.product.image_url,
      });
    }
  }, [data]);

  // Handle quantity change
  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  // Handle add to cart button click
  const handleAddToCart = () => {
    postData("/api/orders", { ...productData, quantity }, true);
  };

  return (
    <section className="d-flex flex-wrap gap-2">
      <div style={{ width: 500 }}>
        <div className="border border-2 border-light px-4 py-2 rounded-top text-bg-light">
          {productData.title}
        </div>
        <div className="border border-2 p-3 rounded-bottom text-bg-dark text-bg-light">
          <div className="d-flex gap-4 flex-wrap">
            <div style={{ width: 150 }}>
              <img
                width={"100%"}
                src={productData.image_url}
                alt="ProductImage"
              />
              <nav>
                price: <span>{productData.price}</span> <span>€</span>
              </nav>
            </div>
            <div style={{ maxWidth: 200 }}>
              <h1 className="text-danger fs-2">{productData.title}</h1>
              <p>{productData.description}</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="border border-2 border-light px-4 py-2 rounded-top text-bg-light">
          Buy this product
        </div>
        <div className="border border-2 p-3 rounded-bottom text-bg-dark text-bg-light">
          <input
            type="number"
            style={{ width: 100 }}
            value={quantity}
            min='1'
            max={data?.product.quantity}
            className="me-2 p-1 ps-2 rounded-5"
            onChange={handleQuantityChange}
          />
          <button
            type="button"
            className="px-3 py-1 rounded-4"
            onClick={handleAddToCart}
          >
            Add to cart
          </button>
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;
