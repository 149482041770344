import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";

const useNestedList = () => {
  // Your state and functions
  const [selectedItem, setSelectedItem] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const createDropdown = (items) => {
    return (
      <Dropdown
        drop="end"
        show={isOpen}
        onMouseEnter={() => setIsOpen(true)}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
        ></Dropdown.Toggle>
        {selectedItem || "Select an item"}
        <Dropdown.Menu>
          {items.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => setSelectedItem(item.title)}
            >
              {item.children ? (
                <div>
                  <p>{item.title}</p>

                  <div> {createDropdown(item.children)} </div>
                </div>
              ) : (
                item.title
              )}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return {
    createDropdown,
  };
};

export default useNestedList;
