import { useContext, useState } from "react";
import BootstrapTable from "react-bootstrap/Table";
import { useNavigate, Link } from "react-router-dom";
import { FilterDataContext } from "../../Store/FilterDataContext";

function CategoryTable(props) {
	const navigate = useNavigate();
	const [data, setData] = useState(props.data);
	const { ChangeFilterData } = useContext(FilterDataContext);
	const initialData = {
		name: "Home",
		Link: "/",
		childrens: [
			{ name: "ContactUs", Link: "/ContactUs", id: 0 },
			{ name: "FAQ", Link: "/FAQ", id: 1 },
			{ name: "CreateAccount", Link: "/CreateAccount", id: 2 },
		],
	};

	return (
		<div>
			<BootstrapTable
				striped
				bordered
				hover
				style={{ border: "1px solid black" }}>
				<thead>
					<tr>
						<th
							onClick={() => navigate(initialData.Link || null)}
							className="bg-dark text-white text-center"
							style={{ cursor: "pointer" }}>
							{data?.name || initialData?.name}
						</th>
					</tr>
				</thead>
				<tbody>
					{data?.childrens?.map((row, index) => (
						<tr key={row.id}>
							<td
								onClick={() => ChangeFilterData(row.id)}
								style={{
									background: index % 2 === 0 ? "#cbcbcb" : "transparent",
									cursor: "pointer",
								}}>
								{row.name}
							</td>
						</tr>
					)) ||
						initialData?.childrens?.map((row, index) => (
							<tr key={row.id}>
								<td
									style={{
										background: index % 2 === 0 ? "#cbcbcb" : "transparent",
										cursor: "pointer",
									}}
									onClick={() => navigate(row.Link)}>
									{row.name}
								</td>
							</tr>
						))}
				</tbody>
			</BootstrapTable>
		</div>
	);
}

export default CategoryTable;
