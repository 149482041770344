import { useState, useEffect } from "react";
import createAxiosInstance, { createPrivateAxiosInstance } from "../Axios";
import { useContext } from "react";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const loadingAlert = Swal.mixin({
  customClass: {
    confirmButton: "alertButton",
    confirmButtonText: "alertButtonText",
    popup: "alertPopup ",
    title: "alertTitle",
    actions: "alertActions",
    container: "alertContainer",
  },
});
const alertError = Swal.mixin({
  customClass: {
    confirmButton: "alertButton",
    confirmButtonText: "alertButtonText",
    popup: "alertPopup ",
    title: "alertTitle",
    actions: "alertActions",
    container: "alertContainer",
  },
});

const useAxiosGet = (url, isPrivate) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { AuthInfo } = useContext(AuthInfoContext);
  const navigate=useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Show loading indicator
        loadingAlert.fire({
          title: "Loading...",
          allowOutsideClick: false,
          showConfirmButton: false,
          icon: "info",
          allowEscapeKey: false,
          onBeforeOpen: () => {
            loadingAlert.showLoading();
          },
        });

        const axiosInstance = isPrivate
          ? createPrivateAxiosInstance(AuthInfo)
          : createAxiosInstance();
        const response = await axiosInstance.get(url);

        console.log("Response:", response); // Log the response object

        if (response && response.data) {
          setData(response.data);
          setLoading(false);
        } else {
          setError(new Error("Empty response data"));
          setLoading(false);
        }

        // Close loading indicator
        loadingAlert.close();
      } catch (error) {
        setError(error);
        setLoading(false);
        console.log(error);
        if (error?.response?.status === 401) {
          navigate("/CreateAccount");
        }
        // Close loadingAlert indicator
        loadingAlert.close();

        // Show error alert
        alertError.fire({
          title:
            error.response.data.message ||
            error.response.data.errors?.join("<br>"),
          icon: "error",
        });
      }
    };

    fetchData();
  }, []);

  return { data, loading, error };
};

export default useAxiosGet;
