import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import FormInput from "../../../Components/FormInput/FormInput";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";

export const ResetPassword = () => {
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		confirmPassword: "",
	});

	const { postData } = useAxiosPost();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = new FormData();
		data.append("email", formData.email);
		data.append("password", formData.password);
		data.append("password_confirmation", formData.confirmPassword);

		postData("/api/auth/reset-password", data);
	};

	return (
		<div className="border border-4 w-100 border-black py-3 px-4 rounded-4 mb-2">
			<Form onSubmit={handleSubmit}>
				<FormInput
					label="Email"
					type="email"
					placeholder="Enter your email"
					name="email"
					value={formData.email}
					onChange={handleChange}
				/>
				<FormInput
					label="New Password"
					type="password"
					placeholder="Enter your new password"
					name="password"
					value={formData.password}
					onChange={handleChange}
				/>
				<FormInput
					label="Confirm Password"
					type="password"
					placeholder="Confirm your new password"
					name="confirmPassword"
					value={formData.confirmPassword}
					onChange={handleChange}
				/>
				<div className="d-flex justify-content-center">
					<Button type="submit" className="mt-3" variant="outline-dark">
						Reset Password
					</Button>
				</div>
			</Form>
		</div>
	);
};
