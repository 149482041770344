import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import FormInput from "../../../Components/FormInput/FormInput";
import { useParams } from "react-router-dom";
import useAxiosPut from "../../../Utilities/UseAxiosPut/UseAxiosPut";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
const EditCategory = () => {
	const params = useParams();
	const { putData: updateCategory } = useAxiosPut();
	const {postData} = useAxiosPost();
	const { data: categoryData } = useAxiosGet(
		`/dashboard/categories/${params.id}`,
		true,
	);
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		_method:"PUT",
	});

	useEffect(() => {
		if (categoryData) {
			setFormData({
				name: categoryData.category.name,
				description: categoryData.category.description,
				_method:"PUT",
			});
		}
	}, [categoryData]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);
		postData(`/dashboard/categories/${params.id}`, formData,true);
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				className="border border-3 border-black p-3 rounded-4 w-75">
				<h2>Edit category</h2>
				<FormInput
					label="Category Name"
					type="text"
					placeholder="Enter name"
					name="name"
					value={formData.name}
					onChange={handleChange}
				/>
				<FormInput
					label="Description"
					type="textarea"
					row={4}
					placeholder="Enter description..."
					name="description"
					value={formData.description}
					onChange={handleChange}
				/>
				<Button variant="outline-dark" className="w-100 mt-2" type="submit">
					Edit
				</Button>
			</Form>
		</>
	);
};

export default EditCategory;
