import { useContext, useState } from "react";
import { createPrivateAxiosInstance } from "../Axios";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const alert = Swal.mixin({
	customClass: {
		confirmButton: "alertButton",
		confirmButtonText: "alertButtonText",
		cancelButton: "alertcancel",
		popup: "alertPopup ",
		title: "alertTitle",
		actions: "alertActions",
		container: "alertContainer",
	},
});
const alertError = Swal.mixin({
	customClass: {
		confirmButton: "alertButton",
		confirmButtonText: "alertButtonText",
		popup: "alertPopup ",
		title: "alertTitle",
		actions: "alertActions",
		container: "alertContainer",
	},
});

const useAxiosDelete = () => {
	const { AuthInfo } = useContext(AuthInfoContext);
	const navigate = useNavigate();
	const deleteData = async (url) => {
		try {
			const axiosInstance = createPrivateAxiosInstance(AuthInfo);

			// Ask for confirmation before deletion
			const confirmResult = await alert.fire({
				title: "Are you sure?",
				text: "You won't be able to revert this!",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Yes, delete it!",
			});

			if (confirmResult.isConfirmed) {
				const response = await axiosInstance.delete(url);

				alert
					.fire({
						title: "Deleted Successfully",
						icon: "success",
						confirmButtonText: "OK",
					})
					.then((result) => {
						if (result.isConfirmed) {
							window.location.reload();
						}
					});
			}
		} catch (error) {
			console.log(error);
			if (error?.response?.status === 401) {
				navigate("/CreateAccount");
			}
			// Show error alert
			alertError.fire({
				title: "Delete Failed",
				text:
					error.response.data.message ||
					error.response.data.errors?.join("<br>"),
				icon: "error",
			});
		}
	};

	return { deleteData };
};

export default useAxiosDelete;
