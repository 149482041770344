import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
import { BASE_URL } from "../../../Utilities/Axios";

const ForgatPassword = () => {
  const { postData } = useAxiosPost();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const email = e.target.elements.email.value;

    const resetData = {
      email,
      reset_link: `https://aromacbdhb.de/#/ResetPassword`,
    };

    await postData("/api/auth/forgot-password", resetData);
  };

  return (
    <div className="border border-4  border-black py-3 px-4 rounded-3 mb-2">
      <Form onSubmit={handleResetPassword}>
        <p className="text-danger">
          If you cannot access your account or forgot your password. Fill out
          your e-mail address below to reset your password.
        </p>
        <Form.Group
          as={Row}
          className="text-black mb-3"
          controlId="formPlaintextEmail"
        >
          <Form.Label column sm="3" className="border-secondary-subtle">
            Email:
          </Form.Label>
          <Col sm="9">
            <Form.Control name="email" defaultValue="email@example.com" />
          </Col>
        </Form.Group>

        <Button type="submit" className="w-100 mt-3" variant="outline-dark">
          Reset Password
        </Button>
      </Form>
    </div>
  );
};

export default ForgatPassword;
