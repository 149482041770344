import LogInNavBar from "../LogInNavBar/LogInNavBar";
import "./Header.css";
import LogoImage from "../../Assets/svg/HB_logo_with_Title.svg";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCartPlus } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import useAxiosPost from "../../Utilities/UseAxiosPost/UseAxiosPost";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const { AuthInfo ,ChangeAuthInfo } = useContext(AuthInfoContext);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const { postData } = useAxiosPost();
  const navigate = useNavigate();
  useEffect(() => {
    if (AuthInfo?.user?.fname != null) setisLoggedIn(true);
    else setisLoggedIn(false);
  }, [AuthInfo]);

  const handleLogOut = () => {
    localStorage.setItem("userToken", "");
    postData("/api/auth/logout", {}, true);
    setisLoggedIn(false);
    localStorage.setItem("persist", "");
    ChangeAuthInfo({})
    navigate("/CreateAccount");
  };

  return (
    <div className="Header p-2">
      <div className="LeftHeader h-100">
        <img
          src={LogoImage}
          alt="logo of HB"
          height={"100%"}
          style={{ background: "none" }}
          onClick={()=>navigate('/')}
        />
      </div>
      <div className="RightHeader">
        {isLoggedIn ? (
          <div style={{ textAlign: "center" }}>
            <div>
              {" "}
              <p> welcome {AuthInfo?.user?.fname} </p>{" "}
              <Button variant="outline-dark" onClick={handleLogOut}>
                log out
              </Button>{" "}
            </div>
            <Link
              to="ShoppingCart"
              style={{ color: "black", textDecoration: "none" }}
            >
              Shopping Cart
              <FaCartPlus />
            </Link>
          </div>
        ) : (
          <LogInNavBar />
        )}
      </div>
    </div>
  );
};

export default Header;
