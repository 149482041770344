import { useState } from "react";
import { createContext } from "react";

const AuthInfoContext = createContext({});
const UseAuthInfoContext = ({ children }) => {
  const [AuthInfo, setAuthInfo] = useState({});
  const ChangeAuthInfo = (newObj) => {
    setAuthInfo(newObj);
  };

  return (
    <AuthInfoContext.Provider value={{ AuthInfo, ChangeAuthInfo }}>
      {children}
    </AuthInfoContext.Provider>
  );
};

export { UseAuthInfoContext, AuthInfoContext };
