import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./Products.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
import { FilterDataContext } from "../../../Store/FilterDataContext";
import { useNavigate } from "react-router-dom";

function Products(props) {
  const { FilterData } = useContext(FilterDataContext);
  const [products, setProducts] = useState(props.data);
  const navigate = useNavigate();
  const [quantity, setquantity] = useState(1);
  const { postData } = useAxiosPost();
  const url = "/api/orders";

  useEffect(() => {
    setProducts(props.data);
  }, [props.data, FilterData]);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(4, products?.length), // Adjust slidesToShow dynamically
    slidesToScroll: Math.min(4, products?.length), // Adjust slidesToScroll dynamically
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(3, products?.length),
          slidesToScroll: Math.min(3, products?.length),
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(2, products?.length),
          slidesToScroll: Math.min(2, products?.length),
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(1, products?.length),
          slidesToScroll: Math.min(1, products?.length),
        },
      },
    ],
  };

  return (
    <div className="products slider-container cardProducts overflow-hidden p-5 pt-0">
      <Slider {...settings}>
        {products &&
          products.map((product) => (
            <div key={product.id} className="cards">
              <Card className="card m-2" style={{ textAlign: "center" }}>
                <Card.Body>
                  <Card.Title
                    className="text-truncate"
                    onClick={() => navigate(`/ProductDetail/${product.id}`)}
                  >
                    {product.name}
                  </Card.Title>
                  <Card.Img
                    variant="top"
                    src={product.image_url}
                    alt={`Image for ${product.name}`}
                    style={{ width: "100%", height: "170px" }}
                    onClick={() => navigate(`/ProductDetail/${product.id}`)}
                  />
                  <Card.Title
                    className="text-truncate"
                    onClick={() => navigate(`/ProductDetail/${product.id}`)}
                  >
                    {product.name}
                  </Card.Title>
                  <Card.Text
                    className="text-truncate"
                    onClick={() => navigate(`/ProductDetail/${product.id}`)}
                  >
                    {product.description}
                  </Card.Text>
                  <span className="Cart">
                    <input
                      className="w-40 mr-2"
                      type="number"
                      defaultValue="1"
                      min="1" // Set minimum value to 1
                      onChange={(e) => setquantity(e.target.value)}
                    />
                    <Button
                      variant="dark"
                      size="sm"
                      onClick={() => {
                        postData(
                          url,
                          {
                            product_id: product.id,
                            quantity: quantity,
                          },
                          true
                        );
                        console.log({
                          product_id: product.id,
                          quantity: quantity,
                        });
                      }}
                    >
                      Add to cart
                    </Button>
                  </span>
                </Card.Body>
              </Card>
            </div>
          ))}
      </Slider>
    </div>
  );
}

export default Products;
