import { Button, Nav } from "react-bootstrap";
import logo from "../../../Assets/svg/HB_logo.svg";
import { Link } from "react-router-dom";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
import { useNavigate } from "react-router-dom";
import { AuthInfoContext } from "../../../Store/AuthInfoContext";
import { useContext } from "react";
function SideBar() {
	const navigate = useNavigate();
	const { ChangeAuthInfo } = useContext(AuthInfoContext);
	const { postData } = useAxiosPost();
	const handleLogOut = () => {
		ChangeAuthInfo({});
		localStorage.setItem("userToken", "");
		postData("/api/auth/logout", {}, true);
		localStorage.setItem("persist", "");
		navigate("/CreateAccount");
	};
	return (
		<>
			<Nav
				defaultActiveKey="/home"
				style={{ background: "linear-gradient(45deg, black, transparent)" }}
				className="flex-column col-2 position-fixed vh-100">
				<Nav.Link href="/" className="m-auto my-3">
					<img
						src={logo}
						alt="logo"
						width={"100%"}
						height={90}
						className=" m-auto "
					/>
				</Nav.Link>
				<Nav.Link
					as={Link}
					className=" w-100 text-center text-truncate fs-5 border-top  text-white fw-bold"
					to={"/Admin/ViewTables/users"}>
					User
				</Nav.Link>
				<Nav.Link
					className=" w-100 text-center text-truncate fs-5 border-top  text-white fw-bold"
					as={Link}
					to="/Admin/ViewTables/categories">
					Categories
				</Nav.Link>
				<Nav.Link
					className=" w-100 text-center text-truncate fs-5 border-top border-bottom text-white fw-bold"
					as={Link}
					to="/Admin/ViewTables/products">
					Products
				</Nav.Link>
				<Nav.Link
					className=" w-100 text-center text-truncate fs-5 border-top border-bottom text-white fw-bold"
					as={Link}
					to="/Admin/ViewTables/contact-us">
					ContactUs
				</Nav.Link>
				<Nav.Link
					className=" w-100 text-center text-truncate fs-5 border-top border-bottom text-white fw-bold"
					as={Link}
					to="/Admin/ViewTables/orders">
					Orders
				</Nav.Link>

				<Button
					className="w-100 position-absolute bottom-0 mb-5"
					variant="outline-light"
					onClick={handleLogOut}>
					log out
				</Button>{" "}
			</Nav>
		</>
	);
}

export default SideBar;
