import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import FormInput from "../../../Components/FormInput/FormInput";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";

const AddCategory = () => {
	const [formData, setFormData] = useState({
		name: "",
		description: "",
	});
	const { postData } = useAxiosPost();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = {
			name: formData.name,
			description: formData.description,
		};

		postData("/dashboard/categories", data, true);
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				className="border border-3 border-black p-3 rounded-4 w-75">
				<h2>Add category</h2>
				<FormInput
					label="Category Name"
					type="text"
					placeholder="Enter name"
					name="name"
					value={formData.name}
					onChange={handleChange}
				/>
				<FormInput
					label="Description"
					type="textarea"
					row={4}
					placeholder="Enter description..."
					name="description"
					value={formData.description}
					onChange={handleChange}
				/>
				<Button variant="outline-dark" className="w-100 mt-2" type="submit">
					Add
				</Button>
			</Form>
		</>
	);
};

export default AddCategory;
