import React from "react";
import Register from "../../User/Account/Register";

const AddAccount = () => {
	return (
		<div className="border border-4  border-black py-3 px-4 rounded-3 mb-2">
			<Register Role="Admin" Type="Add" />
		</div>
	);
};

export default AddAccount;
