import { useContext } from "react";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import { useLocation, Navigate, Outlet } from "react-router-dom";
const RequireAuth = ({ allowedRoles }) => {
  const { AuthInfo } = useContext(AuthInfoContext);
  const location = useLocation();

  return (
    <>
      {AuthInfo?.user?.token ? (
        allowedRoles?.includes(AuthInfo?.user?.admin) === true ? (
          <Outlet />
          
        ) : (
          // console.log(allowedRoles?.includes(AuthInfo?.user?.admin))
          <Navigate state={{ from: location }} replace to={"/"} />
        )
      ) : (
        <Navigate state={{ from: location }} replace to={"/CreateAccount"} />
      )}
    </>
  );
};

export default RequireAuth;
