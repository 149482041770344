import React, { useContext, useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
import { AuthInfoContext } from "../../../Store/AuthInfoContext";
import FormInput from "../../../Components/FormInput/FormInput";

const Register = (props) => {
	const { postData, responseData } = useAxiosPost();
	const { ChangeAuthInfo } = useContext(AuthInfoContext);
	const url =
		props?.Role === "Admin" && props?.Type === "Edit"
			? `/dashboard/users/${props?.userId}`
			: props?.Role === "Admin" && props?.Type === "Add"
			? "/dashboard/users"
			: "/api/auth/register";

	const [formData, setFormData] = useState({
		name: "",
		lastName: "",
		dobYear: "",
		dobMonth: "",
		dobDay: "",
		address1: "",
		address2: "",
		postalCode: "",
		city: "",
		country: "Albania",
		email: "",
		password: "",
	});

	// Update formData state when props.Info changes
	useEffect(() => {
		if (props?.Type === "Edit" && props?.Info) {
			setFormData({
				name: props.Info.fname || "",
				lastName: props.Info.lname || "",
				dobYear: props.Info.year || "",
				dobMonth: props.Info.month || "",
				dobDay: props.Info.day || "",
				address1: props.Info.address_1 || "",
				address2: props.Info.address_2 || "",
				postalCode: props.Info.postal_code || "",
				city: props.Info.city || "",
				country: props.Info.country || "Albania",
				email: props.Info.email || "",
				password: "",
			});
		}
	}, [props.Type, props.Info]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = new FormData();
		data.append("fname", formData.name);
		data.append("lname", formData.lastName);
		data.append(
			"date_of_birth",
			`${formData.dobYear}-${formData.dobMonth}-${formData.dobDay}`
		);
		data.append("address_1", formData.address1);
		data.append("address_2", formData.address2);
		data.append("postal_code", formData.postalCode);
		data.append("city", formData.city);
		data.append("country", formData.country);
		data.append("email", formData.email);

		if (props.Role === "Admin" && props?.Type === "Add") {
			data.append("password", formData.password);
			await postData(url, data, true);
		} else if (props?.Role === "Admin" && props?.Type === "Edit") {
			data.append("_method", "PUT");
			console.log([...data]);
			await postData(url, data, true);
		} else {
			data.append("password", formData.password);
			await postData(url, data, false);
			ChangeAuthInfo(responseData);
		}
	};
	const generateOptions = (start, end) => {
		const options = [];
		for (let i = start; i <= end; i++) {
			options.push(
				<option key={i} value={i}>
					{i}
				</option>
			);
		}
		return options;
	};
	const countries = ["germany"];

	const cities = [
		"Baden-Württemberg - Stuttgart",
		" Bavaria (Bayern) - Munich (München)",
		"Berlin - Berlin (it is both a city and a state)",
		"  Brandenburg - Potsdam",
		" Bremen - Bremen (it is both a city and a state)",
		"Hamburg - Hamburg (it is both a city and a state)",
		"Hesse (Hessen) - Wiesbaden",
		"Lower Saxony (Niedersachsen) - Hanover (Hannover)",
		"Mecklenburg-Western Pomerania (Mecklenburg-Vorpommern) - Schwerin",
		"North Rhine-Westphalia (Nordrhein-Westfalen) - Düsseldorf",
		"Rhineland-Palatinate (Rheinland-Pfalz) - Mainz",
		"Saarland - Saarbrücken",
		"Saxony (Sachsen) - Dresden",
		"Saxony-Anhalt (Sachsen-Anhalt) - Magdeburg",
		"Schleswig-Holstein - Kiel",
		" Thuringia (Thüringen) - Erfurt",
	];

	return (
		<Container>
			<Row className="text-black justify-content-md-center w-100">
				<h2>{props.Type === "Edit" ? "Edit" : "Create a new"} account</h2>
				<p>
					You first have to create an account before you place an order. Fill in
					the details below to {props.Type === "Edit" ? "edit" : "register"}.
				</p>
				<form onSubmit={handleSubmit} className="w-100">
					<FormInput
						label="Name"
						type="text"
						placeholder="Enter name"
						name="name"
						value={formData.name}
						onChange={handleChange}
					/>
					<FormInput
						label="Lastname"
						type="text"
						placeholder="Enter lastname"
						name="lastName"
						value={formData.lastName}
						onChange={handleChange}
					/>
					<Form.Group className="text-black mt-2" as={Row}>
						<Form.Label column sm="3">
							Date of birth:
						</Form.Label>
						<Col sm="9">
							<div className="d-flex flex-wrap">
								<Form.Control
									className="me-2 border border-dark-subtle"
									style={{ width: "75px" }}
									as="select"
									name="dobYear"
									value={formData.dobYear}
									onChange={handleChange}>
									<option value="">Year</option>
									{generateOptions(1900, 2024)}
								</Form.Control>

								<Form.Control
									className="me-2 border border-dark-subtle"
									style={{ width: "75px" }}
									as="select"
									name="dobMonth"
									value={formData.dobMonth}
									onChange={handleChange}>
									<option value="">Month</option>
									{generateOptions(1, 12)}
								</Form.Control>

								<Form.Control
									className="me-2 border border-dark-subtle"
									style={{ width: "75px" }}
									as="select"
									name="dobDay"
									value={formData.dobDay}
									onChange={handleChange}>
									<option value="">Day</option>
									{generateOptions(1, 31)}
								</Form.Control>
							</div>
						</Col>
					</Form.Group>
					<FormInput
						label="Address1"
						type="text"
						placeholder="Enter address"
						name="address1"
						value={formData.address1}
						onChange={handleChange}
					/>
					<FormInput
						label="Address2 (optional)"
						type="text"
						placeholder="Enter address (optional)"
						name="address2"
						value={formData.address2}
						onChange={handleChange}
					/>
					<FormInput
						label="Postalcode"
						type="text"
						placeholder="Enter postalcode"
						name="postalCode"
						value={formData.postalCode}
						onChange={handleChange}
					/>

					<Form.Group
						className="text-black mt-2"
						as={Row}
						controlId="formCountry">
						<Form.Label column sm="3">
							Country:
						</Form.Label>
						<Col sm="9">
							<Form.Control
								className="border border-dark-subtle"
								as="select"
								name="country"
								value={formData.country}
								onChange={handleChange}>
								{countries.map((country, index) => (
									<option key={index} value={country}>
										{country}
									</option>
								))}
							</Form.Control>
						</Col>
					</Form.Group>
					<Form.Group
						className="text-black mt-2"
						as={Row}
						controlId="formCountry">
						<Form.Label column sm="3">
							City:
						</Form.Label>
						<Col sm="9">
							<Form.Control
								className="border border-dark-subtle"
								as="select"
								name="city"
								value={formData.city}
								onChange={handleChange}>
								{cities.map((city, index) => (
									<option key={index} value={city}>
										{city}
									</option>
								))}
							</Form.Control>
						</Col>
					</Form.Group>
					<FormInput
						label="Email"
						type="email"
						placeholder="Enter email"
						name="email"
						value={formData.email}
						onChange={handleChange}
					/>
					{props.Type !== "Edit" && (
						<FormInput
							label="Password"
							type="password"
							placeholder="Enter password"
							name="password"
							value={formData.password}
							onChange={handleChange}
						/>
					)}
					<Button variant="outline-dark" className="w-100 mt-2" type="submit">
						{props.Type === "Edit" ? "Edit" : "Register"}
					</Button>
				</form>
			</Row>
		</Container>
	);
};

export default Register;
