import { useState, useContext } from "react";
import { createPrivateAxiosInstance } from "../Axios";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
const alert = Swal.mixin({
	customClass: {
		confirmButton: "alertButton",
		confirmButtonText: "alertButtonText",
		cancelButton: "alertcancel",
		popup: "alertPopup ",
		title: "alertTitle",
		actions: "alertActions",
		container: "alertContainer",
	},
});
const loadingAlert = Swal.mixin({
	customClass: {
		confirmButton: "alertButton",
		confirmButtonText: "alertButtonText",
		popup: "alertPopup ",
		title: "alertTitle",
		actions: "alertActions",
		container: "alertContainer",
	},
});
const alertError = Swal.mixin({
	customClass: {
		confirmButton: "alertButton",
		confirmButtonText: "alertButtonText",
		popup: "alertPopup ",
		title: "alertTitle",
		actions: "alertActions",
		container: "alertContainer",
	},
});

const useAxiosPut = () => {
	const { AuthInfo } = useContext(AuthInfoContext);
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const navigate = useNavigate();
	const putData = async (url, putData, to = null) => {
		try {
			setLoading(true);

			const axiosInstance = createPrivateAxiosInstance(AuthInfo);

			// Show loading indicator
			loadingAlert.fire({
				title: "Loading...",
				allowOutsideClick: false,
				showConfirmButton: false,
				icon: "info",
				allowEscapeKey: false,
				onBeforeOpen: () => {
					loadingAlert.showLoading();
				},
			});

			const response = await axiosInstance.put(url, putData);
			setData(response.data);
			setLoading(false);

			// Close loading indicator
			Swal.close();

			alert
				.fire({
					title: response.data.message,
					icon: "success",
					confirmButtonText: "OK",
				})
				.then((result) => {
					if (result.isConfirmed) {
						to ? navigate(to) : window.location.reload();
					}
				});
		} catch (error) {
			setError(error);
			setLoading(false);
			if (error?.response?.status === 401) {
				navigate("/CreateAccount");
			}
			console.log(error);

			Swal.close();

			alertError.fire({
				title:
					error.response.data.message ||
					error.response.data.errors?.join("<br>"),
				icon: "error",
			});
		}
	};

	return { data, loading, error, putData };
};

export default useAxiosPut;
