import axios from "axios";

export const BASE_URL = "https://backend.aromacbdhb.de";

export default function createAxiosInstance() {
	return axios.create({
		baseURL: BASE_URL,
		headers: {
			
			"Content-Type": "application/json",
		},
	});
}

export const createPrivateAxiosInstance = (authInfo) => {
	return axios.create({
		baseURL: BASE_URL,
		headers: {
			"Content-Type": "multipart/form-data;",
			Authorization: `Bearer ${authInfo?.user?.token}`,
		},
	});
};
