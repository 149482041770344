import React, { useEffect, useState } from "react";
import Register from "../../User/Account/Register";
import { useParams } from "react-router-dom";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";
const EditUser = () => {
  const params = useParams();
  const { data } = useAxiosGet(`/dashboard/users/${params.id}`, true);
  const [user, setUser] = useState({});
  useEffect(() => {
    setUser(data?.user);
  }, [data]);

  const [day, month, year] = user?.date_of_birth?.split("-") || [
    undefined,
    undefined,
    undefined,
  ];
  const updatedUser = {
    ...user,
    day: parseInt(day),
    month: parseInt(month),
    year: parseInt(year),
  };
  return (
    <div className="border border-4  border-black py-3 px-4 rounded-3 mb-2">
      <Register
        Role="Admin"
        Type="Edit"
        Info={updatedUser}
        userId={params.id}
      />
    </div>
  );
};

export default EditUser;
