import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = (props) => {
  const Mainpages = [
    {
      name: "Home",
      to: "/",
    },
    {
      name: "ContactUs",
      to: "/ContactUs",
    },
    {
      name: "FAQ",
      to: "/FAQ",
    },
  ];
  const pages = [
    { name: "Login", to: "/CreateAccount" },
    {
      name: "CreateAccount",
      to: "/CreateAccount",
    },
    {
      name: "Forgot password ",
      to: "/forgotPassword",
    },
    {
      name: "Shopping cart",
      to: "/ShoppingCart",
    },
  ];
  const social = [
    "Like us on facebook",
    "Terms and conditions",
    "Our store ",
    "Like us",
  ];
  const Categories = props.data;
  return (
    <div className="Footer">
      <div className="FootersList">
        <ul>
          {Mainpages.map((mainPage, index) => (
            <li>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                key={index}
                to={mainPage.to}
              >
                {mainPage.name}
              </Link>
            </li>
          ))}
        </ul>
        <ul>
          {pages.map((page, index) => (
            <li>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                key={index}
                to={page.to}
              >
                {page.name}
              </Link>
            </li>
          ))}
        </ul>
        <ul>
          {social.map((page, index) => (
            <li>{page}</li>
          ))}
        </ul>
        <ul>
          {Categories?.slice(0, 4).map((product, index) => (
            <li key={index}>
              {product.name}
            </li>
          ))}
        </ul>
      </div>
      <p>© 1996 Magic Mushroom Gallery Amsterdam</p>
    </div>
  );
};

export default Footer;
