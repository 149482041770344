import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { Link, useNavigate } from "react-router-dom";
import useAxiosPost from "../../Utilities/UseAxiosPost/UseAxiosPost";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
function LogInNavBar() {
  const navigate = useNavigate();
  const { ChangeAuthInfo } = useContext(AuthInfoContext);
  const [showFormGroups, setShowFormGroups] = useState(true);
  const [FinalResult, setFinalResult] = useState(0);
  const { postData, responseData } = useAxiosPost();
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  const url = "/api/auth/login";

  useEffect(() => {
    const handleResize = () => {
      setShowFormGroups(window.innerWidth >= 730);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    ChangeAuthInfo(responseData);
    if (responseData?.user?.token) {
      localStorage.setItem("userToken", responseData?.user?.token);
    }
    if (localStorage.getItem("userToken") != undefined) {
      localStorage.setItem("persist", true);
    }
  }, [responseData]);

  const handleSubmit = (event) => {
    event.preventDefault();
    showFormGroups && postData(url, userInfo, false);
    
    !showFormGroups && navigate("/CreateAccount");
  };

  const handleChange = (e) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row className="align-items-end">
          {showFormGroups && (
            <>
              <Form.Group as={Col} md="4" controlId="validationCustom01">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={handleChange}
                  value={userInfo.email}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationCustom02">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={handleChange}
                  value={userInfo.password}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
            </>
          )}
          <Button
            style={{ height: "40px", width: "150px" }}
            type="submit"
            variant="outline-dark"
            className="mt-3 col"
          >
            Log in
          </Button>
        </Row>
        <Form.Label className="fs-6">
          <Link
            to={"/CreateAccount"}
            style={{
              textDecoration: "none",
              color: "gray",
              fontSize: "calc(8px + 1vw)",
            }}
          >
            Create account
          </Link>{" "}
          |{" "}
          <Link
            to={"/forgotpassword"}
            style={{
              textDecoration: "none",
              color: "gray",
              fontSize: "calc(8px + 1vw)",
            }}
          >
            Forgot password
          </Link>
        </Form.Label>
      </Form>
    </div>
  );
}

export default LogInNavBar;
