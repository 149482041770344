import Login from "./Login";
import Register from "./Register";

const CreateAccount = () => {
	return (
		<section className="d-flex flex-column">
			{/* <div
				style={{ background: "gray", height: "9px", paddingLeft: "25%" }}
				className="text-black align-items-center d-flex justify-content-around my-4 rounded w-100">
				<nav
					style={{ background: "gray", border: "2px solid gray" }}
					className="p-3 rounded-5 position-relative">
					<span
						className="position-absolute"
						style={{ top: "-100%", marginLeft: "-75%" }}>
						Register
					</span>
				</nav>
				<nav
					style={{ background: "white", border: "2px solid gray" }}
					className="p-3 rounded-5 position-relative">
					<span
						className=" position-absolute"
						style={{ top: "-100%", marginLeft: "-75%" }}>
						SignIn
					</span>
				</nav>
				<nav
					style={{ background: "white", border: "2px solid gray" }}
					className="p-3 rounded-5 position-relative">
					<span
						className=" position-absolute"
						style={{ top: "-100%", marginLeft: "-75%" }}>
						SignIn
					</span>
				</nav>
				<nav
					style={{ background: "white", border: "2px solid gray" }}
					className="p-3 rounded-5 position-relative">
					<span
						className=" position-absolute"
						style={{ top: "-100%", marginLeft: "-75%" }}>
						SignIn
					</span>
				</nav>
			</div> */}
			<div className="w-100">
				<div className="border border-4 border-black py-3 px-4 rounded-3 mb-2">
					<p className="text-black">
						You can either create a new account or sign up with your existing
						account that you have used in the past.
					</p>
				</div>
				<div className="border border-4 border-black py-3 px-4 rounded-3 mb-2">
					<Login />
				</div>
				<div className="border border-4 border-black py-3 px-4 rounded-3 mb-2">
					<Register />
				</div>
			</div>
		</section>
	);
};

export default CreateAccount;
