import { useState } from "react";
import createAxiosInstance, { createPrivateAxiosInstance } from "../Axios";
import { useContext } from "react";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import Swal from "sweetalert2";
import UseRefreshToken from "../UseRefreshToken/UseRefreshToken";
import { useNavigate } from "react-router-dom";
const alert = Swal.mixin({
  customClass: {
    confirmButton: "alertButton",
    confirmButtonText: "alertButtonText",
    cancelButton: "alertcancel",
    popup: "alertPopup ",
    title: "alertTitle",
    actions: "alertActions",
    container: "alertContainer",
  },
});
const loadingAlert = Swal.mixin({
  customClass: {
    confirmButton: "alertButton",
    confirmButtonText: "alertButtonText",
    popup: "alertPopup ",
    title: "alertTitle",
    actions: "alertActions",
    container: "alertContainer",
  },
});
const alertError = Swal.mixin({
  customClass: {
    confirmButton: "alertButton",
    confirmButtonText: "alertButtonText",
    popup: "alertPopup ",
    title: "alertTitle",
    actions: "alertActions",
    container: "alertContainer",
  },
});

const useAxiosPost = () => {
  const { AuthInfo } = useContext(AuthInfoContext);
  const [responseData, setResponseData] = useState({});
  const refresh = UseRefreshToken();
  const navigate=useNavigate();

  const [presist, setpresist] = useState(localStorage.getItem("persist"));
  const postData = async (url, postData, isPrivate = false) => {
    try {
      const axiosInstance = isPrivate
        ? createPrivateAxiosInstance(AuthInfo)
        : createAxiosInstance();

      // Show loading indicator
      loadingAlert.fire({
        title: "Loading...",
        allowOutsideClick: false,
        showConfirmButton: false,
        icon: "info",
        allowEscapeKey: false,
        onBeforeOpen: () => {
          loadingAlert.showLoading();
        },
      });

      const response = await axiosInstance.post(url, postData);

      console.log("Response:", response);

      if (response && response.data) {
        setResponseData(response.data);

        // Close loading indicator
        loadingAlert.close();

        // Show success alert
        alert.fire({
          title: response.data.message,
          icon: "success",
        });
      } else {
        throw new Error("Empty response data");
      }
    } catch (error) {
      console.log("Error:", error);
      if (error?.response?.status === 401) {
        navigate("/CreateAccount");
      }
      // Close loading indicator
      loadingAlert.close();
      if (localStorage.getItem("pay")) {
        console.log("error" + error);
      } else {
        alertError.fire({
          title:
            error.response.data.message ||
            error.response.data.errors?.join("<br>"),
          icon: "error",
        });
      }
    }
  };

  return { postData, responseData };
};

export default useAxiosPost;
