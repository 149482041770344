import React, { useState, useEffect } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import FormInput from "../../../Components/FormInput/FormInput";
import { SelectInput } from "../../../Components/FormInput/SelectInput";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";
import { useParams } from "react-router-dom";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";

export const EditProduct = () => {
	const { id } = useParams();
	const {
		data: productData,
		loading: productLoading,
		error: productError,
	} = useAxiosGet(`/dashboard/products/${id}`, true);
	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = useAxiosGet("/dashboard/categories-with-childrens", true);
	const { postData } = useAxiosPost();

	const [file, setFile] = useState();
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		price: "1",
		quantity: "",
		image: "",
		category_id: "",
		subcategory_id: "",
	});

	const [subcategoryOptions, setSubcategoryOptions] = useState([]);

	useEffect(() => {
		if (productData?.product) {
			setFormData({
				name: productData.product.name || "",
				description: productData.product.description || "",
				price: productData.product.price || "",
				quantity: productData.product.quantity || "0",
				image: productData.product.image_url || "",
				category_id: productData.product.category_id || "",
			});
		}
		if (categoriesData) {
			const selectedCategory = categoriesData.categories.find(
				(category) => category.id == formData.category_id
			);
			const subcategories = selectedCategory ? selectedCategory.childrens : [];
			setSubcategoryOptions(subcategories);
		}
	}, [productData, categoriesData]);

	const handleChangeImg = (e) => {
		const selectImg = e.target.files[0];
		if (selectImg) {
			setFile(selectImg);
			setFormData({
				...formData,
				image: URL.createObjectURL(selectImg),
			});
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "category_id") {
			const selectedCategory = categoriesData.categories.find(
				(category) => category.id == value
			);
			const subcategories = selectedCategory ? selectedCategory.childrens : [];
			setSubcategoryOptions(subcategories);
			setFormData({
				...formData,
				[name]: value,
				subcategory_id: "",
			});
		} else {
			setFormData({
				...formData,
				[name]: value,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = new FormData();
		data.append("name", formData.name);
		data.append("price", formData.price);
		data.append("quantity", formData.quantity);
		data.append("category_id", formData.category_id);
		if (file) {
			data.append("image", file);
		}
		data.append("_method", "PUT");

		postData(`/dashboard/products/${id}`, data, true);
		console.log("Form submitted:", [...data]);

		// Reset the file state after form submission
		setFile(null);
	};

	if (productLoading || categoriesLoading) return <div>Loading...</div>;
	if (productError || categoriesError)
		return <div>Error: {productError || categoriesError}</div>;

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				className="border border-3 border-black p-3 rounded-4 w-75">
				<h2>Edit Product</h2>
				<FormInput
					label="Product Name"
					type="text"
					placeholder="Enter name"
					name="name"
					value={formData.name}
					onChange={handleChange}
				/>
				<FormInput
					label="Description"
					type="textarea"
					row={4}
					placeholder="Enter description..."
					name="description"
					value={formData.description}
					onChange={handleChange}
				/>
				<FormInput
					label="Price"
					type="number"
					name="price"
					value={formData.price}
					onChange={handleChange}
				/>
				<FormInput
					label="Quantity"
					type="number"
					name="quantity"
					value={formData.quantity}
					onChange={handleChange}
				/>
				{categoriesData ? (
					<>
						<SelectInput
							label="Category"
							name="category_id"
							options={categoriesData.categories.map((category, index) => ({
								value: category.id,
								label: category.name,
							}))}
							value={formData.category_id}
							onChange={handleChange}
						/>
						{subcategoryOptions.length > 0 && (
							<SelectInput
								label="Subcategory"
								name="subcategory_id"
								options={subcategoryOptions.map((subcategory) => ({
									value: subcategory.id,
									label: subcategory.name,
								}))}
								value={formData.subcategory_id}
								onChange={handleChange}
							/>
						)}
					</>
				) : (
					<p>Loading categories...</p>
				)}

				<Form.Group controlId="formImage" className="text-black mt-2" as={Row}>
					<Form.Label column sm="3">
						Image:
					</Form.Label>
					<Col sm="9">
						<Form.Control
							className={"border border-dark-subtle"}
							type="file"
							accept="image/*"
							onChange={handleChangeImg}
						/>
					</Col>
				</Form.Group>
				<Button variant="outline-dark" className="w-100 mt-2" type="submit">
					Edit
				</Button>
			</Form>
			<span className="ms-2">
				<Image
					src={formData.image}
					thumbnail
					width={"300px"}
					height={file ? "" : "450px"}
				/>
			</span>
		</>
	);
};

export default EditProduct;
