import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import "./ContactUs.css";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";

const ContactUs = () => {
	const { postData } = useAxiosPost();
	const [formData, setFormData] = useState({
		Name: "",
		Email: "",
		Message: "",
		Subject: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData();
		data.append("subject", formData.Subject);
		data.append("text", formData.Message);
		data.append("name", formData.Name);
		data.append("email", formData.Email);

		await postData("/api/contact-us", data);
	};

	return (
		<div className="ContactUs p-5 pt-0">
			<Form
				onSubmit={handleSubmit}
				className="border p-4 rounded-5 border-black">
				<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
					<Form.Label>
						Subject <i style={{ color: "red" }}>*</i>
					</Form.Label>
					<Form.Control type="text" name="Subject" onChange={handleChange} />
				</Form.Group>
				<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
					<Form.Label>
						Question / Comment / Problem <i style={{ color: "red" }}>*</i>
					</Form.Label>
					<Form.Control
						as="textarea"
						rows={6}
						name="Message"
						onChange={handleChange}
					/>
				</Form.Group>
				<InputGroup
					style={{ width: "100%", flexWrap: "nowrap" }}
					className="mb-3">
					<Form.Group
						className="m-1"
						style={{ width: "49%" }}
						controlId="exampleForm.ControlTextarea1">
						<Form.Label>Name</Form.Label>
						<Form.Control
							aria-label="First name"
							type="text"
							name="Name"
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group
						className="m-1"
						style={{ width: "49%" }}
						controlId="exampleForm.ControlTextarea1">
						<Form.Label> E-mail address</Form.Label>
						<Form.Control
							aria-label="First name"
							type="email"
							name="Email"
							onChange={handleChange}
						/>
					</Form.Group>
				</InputGroup>
				<Button variant="outline-dark" className="w-100" type="submit">
					Send
				</Button>
			</Form>
		</div>
	);
};

export default ContactUs;
