import { AuthInfoContext } from "../../Store/AuthInfoContext";
import { useEffect, useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const UseRefreshToken = () => {
  const { ChangeAuthInfo } = useContext(AuthInfoContext);
  const navigate = useNavigate();
  // useEffect(() => {
  const refresh = async () => {
    try {
      console.log("refresh token called");

      const response = await axios.get(
        "https://backend.aromacbdhb.de/api/auth/refresh",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
          },
        }
      );
      ChangeAuthInfo(response.data);
      if (response.data?.user?.token) {
        localStorage.setItem("userToken", response.data?.user?.token);
      }
      console.log("new token");
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response?.status === 401) {
      }
    }
  };
  // }, []);

  return refresh;
};

export default UseRefreshToken;
