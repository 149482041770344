import useNestedList from "./UseNestedList";
const NestedList = (props) => {
  const { createDropdown } = useNestedList();
  const Items = [
    {
      id: 1,
      title: "Product 1",
      description: "Description for Product 1",
      imageUrl: "https://via.placeholder.com/100x180",
      price: 150,
      children: [
        {
          id: 2,
          title: "Product 2",
          description: "Description for Product 2",
          imageUrl: "https://via.placeholder.com/100x180",
          price: 120,
        },
        {
          id: 3,
          title: "Product 3",
          description: "Description for Product 3",
          imageUrl: "https://via.placeholder.com/100x180",
          price: 130,
        },
      ],
    },
    {
      id: 2,
      title: "Product 2",
      description: "Description for Product 2",
      imageUrl: "https://via.placeholder.com/100x180",
      price: 120,
    },
    {
      id: 3,
      title: "Product 3",
      description: "Description for Product 3",
      imageUrl: "https://via.placeholder.com/100x180",
      price: 130,
    },
    {
      id: 4,
      title: "Product 4",
      description: "Description for Product 4",
      imageUrl: "https://via.placeholder.com/100x180",
      price: 140,
    },
  ];
  return (
    <div>
      {/* {items}
      <p
        onClick={() =>
          addItem('majd')
        }
      >
        {""}
        majd
      </p> */}
      {createDropdown(Items)}
    </div>
  );
};

export default NestedList;
