import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import FormInput from "../../../Components/FormInput/FormInput";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";
import { useContext, useEffect, useState } from "react"; // Import useState
import { AuthInfoContext } from "../../../Store/AuthInfoContext";

function Login() {
	const { ChangeAuthInfo } = useContext(AuthInfoContext);
	const { postData, responseData } = useAxiosPost();
	const [formData, setFormData] = useState({
		// Initialize formData state
		emailLogin: "",
		passwordLogin: "",
	});

	useEffect(() => {
		ChangeAuthInfo(responseData);
	}, [responseData]);

	// Handle form input change
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleLogin = async (e) => {
		e.preventDefault();
		const loginData = {
			email: formData.emailLogin,
			password: formData.passwordLogin,
		};
		console.log(loginData);
		await postData("/api/auth/login", loginData);
	};

	return (
		<Form onSubmit={handleLogin}>
			<p className="text-danger">Login to existing account</p>

			<FormInput
				label="Email"
				type="email"
				placeholder="Enter your email"
				name="emailLogin"
				value={formData.emailLogin}
				onChange={handleInputChange}
			/>

			<FormInput
				label="Password"
				type="password"
				placeholder="Password"
				name="passwordLogin"
				value={formData.passwordLogin}
				onChange={handleInputChange}
			/>

			<div className="row pt-3 align-items-end d-flex flex-column">
				<Col sm="9">
					<Button type="submit" className="w-100" variant="outline-dark">
						Login
					</Button>
				</Col>
				<Col sm="9" className="text-end">
					<Button
						as={Link}
						to={"/forgotPassword"}
						type="button"
						className="w-100 text-danger text-decoration-none"
						variant="link">
						Forgot password?
					</Button>
				</Col>
			</div>
		</Form>
	);
}

export default Login;
