import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useParams } from "react-router-dom";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";

const ShowCategory = () => {
	const { id } = useParams();

	const { data, loading, error } = useAxiosGet(
		`/dashboard/categories/${id}`,
		true
	);

	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;

	if (!data) return null;

	const category = data.category;

	return (
		<div className="ShowCategory">
			<h1 className="fs-1" style={{ color: "gray" }}>
				{category.name} Information
			</h1>
			<ListGroup>
				<ListGroup.Item>
					<span className="fw-bold">id:</span> <span>{category.id}</span>
				</ListGroup.Item>
				<ListGroup.Item>
					<span className="fw-bold">Name:</span> <span>{category.name}</span>
				</ListGroup.Item>
				<ListGroup.Item>
					<span className="fw-bold">Description:</span>{" "}
					<span>{category.description}</span>
				</ListGroup.Item>
				<ListGroup.Item>
					<span className="fw-bold">Products Count:</span>{" "}
					<span>{category.products_count}</span>
				</ListGroup.Item>
			</ListGroup>
		</div>
	);
};

export default ShowCategory;
