import { Col, Form, Row } from "react-bootstrap";

const FormInput = ({
	row,
	label,
	type,
	placeholder,
	name,
	value,
	onChange,
	className = "",
}) => {
	return (
		<Form.Group className={`text-black mt-2 align-items-center`} as={Row}>
			<Form.Label column sm="3">
				{label}:
			</Form.Label>
			<Col sm="9">
				<Form.Control
					className={`border border-dark-subtle`}
					type={type}
					as={type === "textarea" ? type : "input"}
					placeholder={placeholder}
					name={name}
					rows={row}
					value={value}
					onChange={onChange}
					id={`form${name}`}
				/>
			</Col>
		</Form.Group>
	);
};

export default FormInput;
