import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import useAxiosGet from "../../../../Utilities/UseAxiosGet/UseAxiosGet";
import { useParams } from "react-router-dom";
import "./ShowProduct.css";

const ShowProduct = () => {
	const { id } = useParams();
	const { data, loading, error } = useAxiosGet(
		`/dashboard/products/${id}`,
		true
	);
	if (loading) return <div>Loading...</div>;
	if (error) return <div>Error: {error.message}</div>;
	if (!data) return null;

	const product = data?.product || {};

	return (
		<div className="ShowProduct">
			<h1 className="fs-1" style={{ color: "gray" }}>
				{product.name} Information
			</h1>
			<div className="ProductInfo">
				<ListGroup>
					<ListGroup.Item action variant="secondary">
						ID:
						<ListGroup.Item action variant="light">
							{product.id}
						</ListGroup.Item>
					</ListGroup.Item>
					<ListGroup.Item action variant="secondary">
						Name:
						<ListGroup.Item action variant="light">
							{product.name}
						</ListGroup.Item>
					</ListGroup.Item>
					<ListGroup.Item action variant="secondary">
						Description:
						<ListGroup.Item action variant="light">
							{product.description}
						</ListGroup.Item>
					</ListGroup.Item>
					<ListGroup.Item action variant="secondary">
						Quantity:
						<ListGroup.Item action variant="light">
							{product.quantity}
						</ListGroup.Item>
					</ListGroup.Item>
					<ListGroup.Item action variant="secondary">
						Price:
						<ListGroup.Item action variant="light">
							{product.price}
						</ListGroup.Item>
					</ListGroup.Item>
				</ListGroup>
				<span>
					<Image src={product.image_url} thumbnail width={"300px"} />
				</span>
			</div>
		</div>
	);
};

export default ShowProduct;
