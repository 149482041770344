import { useContext, useState, useEffect } from "react";
import { AuthInfoContext } from "../../Store/AuthInfoContext";
import { Outlet } from "react-router-dom";
import UseRefreshToken from "../../Utilities/UseRefreshToken/UseRefreshToken";
const PresistLogin = () => {
  const { AuthInfo } = useContext(AuthInfoContext);
  const [persist, setPersist] = useState(localStorage.getItem("persist"));
  const [isLoading, setisLoading] = useState(true)
  const refresh = UseRefreshToken();
  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      }finally{
        setisLoading(false);
      }
    };
    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !AuthInfo?.user?.token && persist ? verifyRefreshToken() : setisLoading(false);

  }, []);

  return <div>{isLoading ? <p>loading ...</p> : <Outlet/>}</div>;
};

export default PresistLogin;
