import React, { useState } from "react";
import { Button, Col, Form, Image, Row } from "react-bootstrap";
import FormInput from "../../../Components/FormInput/FormInput";
import { SelectInput } from "../../../Components/FormInput/SelectInput";
import useAxiosGet from "../../../Utilities/UseAxiosGet/UseAxiosGet";
import useAxiosPost from "../../../Utilities/UseAxiosPost/UseAxiosPost";

const AddProduct = () => {
	const [file, setFile] = useState(null); // Initialize file state to null
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		price: 0,
		quantity: 0,
		image: "", // Initialize image state as an empty string
		category_id: 0,
		subcategory_id: 0,
	});

	const { data: categoriesData } = useAxiosGet(
		"/dashboard/categories-with-childrens",
		true
	);
	const { postData: postProduct } = useAxiosPost();

	const handleChangeImg = (e) => {
		const selectImg = e.target.files[0];
		if (selectImg) {
			setFile(selectImg);
			setFormData({
				...formData,
				image: URL.createObjectURL(selectImg),
			});
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const data = new FormData();
		data.append("name", formData.name);
		data.append("description", formData.description);
		data.append("price", formData.price);
		data.append("quantity", formData.quantity);
		data.append(
			"category_id",
			formData.subcategory_id !== 0
				? formData.subcategory_id
				: categoriesData.categories[formData.category_id].id
		);

		if (file) {
			data.append("image", file);
		}

		try {
			await postProduct("/dashboard/products", data, true);
			console.log(data);
			// Reset form after successful submission
		} catch (error) {
			console.error("Error submitting product:", error);
			// Handle error (e.g., display error message to the user)
		}
	};

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				className="border border-3 border-black p-3 rounded-4 w-75">
				<h2>Add Product</h2>
				<FormInput
					label="Category Name"
					type="text"
					placeholder="Enter name"
					name="name"
					value={formData.name}
					onChange={handleChange}
				/>
				<FormInput
					label="Description"
					type="textarea"
					row={4}
					placeholder="Enter description..."
					name="description"
					value={formData.description}
					onChange={handleChange}
				/>
				<FormInput
					label="Price"
					type="number"
					name="price"
					value={formData.price}
					onChange={handleChange}
				/>
				<FormInput
					label="Quantity"
					type="number"
					name="quantity"
					value={formData.quantity}
					onChange={handleChange}
				/>
				{categoriesData ? (
					<>
						<SelectInput
							label="Category"
							name="category_id"
							options={categoriesData.categories.map((category, index) => ({
								value: index,
								label: category.name,
							}))}
							value={formData.category_id}
							onChange={handleChange}
						/>
						{categoriesData.categories[formData.category_id]?.childrens
							.length !== 0 && (
							<SelectInput
								label="Subcategory"
								name="subcategory_id"
								options={
									categoriesData.categories[
										formData.category_id
									]?.childrens.map((subcategory) => ({
										value: subcategory.id,
										label: subcategory.name,
									})) || []
								}
								value={formData.subcategory_id}
								onChange={handleChange}
							/>
						)}
					</>
				) : (
					<p>Loading categories...</p>
				)}

				<Form.Group controlId="formImage" className="text-black mt-2" as={Row}>
					<Form.Label column sm="3">
						Image:
					</Form.Label>
					<Col sm="9">
						<Form.Control
							className={"border border-dark-subtle"}
							type="file"
							accept="image/*"
							onChange={handleChangeImg}
						/>
					</Col>
				</Form.Group>
				<Button variant="outline-dark" className="w-100 mt-2" type="submit">
					Add
				</Button>
			</Form>
			<span className="ms-2">
				<Image
					src={formData.image}
					thumbnail
					width={"300px"}
					height={file ? "" : "450px"}
				/>
			</span>
		</>
	);
};

export default AddProduct;
