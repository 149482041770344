import { createContext } from "react";
import { useState } from "react";

const FilterDataContext = createContext({});
const UseFilterDataContext = ({ children }) => {
  const [FilterData, setFilterData] = useState("");
  const ChangeFilterData = (NewFilterData) => {
    setFilterData(NewFilterData);
  };
  console.log(FilterData);
  return (
    <FilterDataContext.Provider value={{ FilterData, ChangeFilterData }}>
      {children}
    </FilterDataContext.Provider>
  );
};

export { UseFilterDataContext, FilterDataContext };
