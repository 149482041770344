import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const SelectInput = ({ label, options, value, onChange, name }) => {
	return (
		<Form.Group className="text-black mt-2" as={Row}>
			<Form.Label column sm="3">
				{label}:
			</Form.Label>
			<Col sm="9">
				<Form.Select
					name={name}
					className={"border border-dark-subtle"}
					aria-label={`Select ${label}`}
					value={value}
					onChange={onChange}>
					{options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.label}
						</option>
					))}
				</Form.Select>
			</Col>
		</Form.Group>
	);
};
