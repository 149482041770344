import { Accordion } from "react-bootstrap";

const FAQ = () => {
	const question = [
		{
			question: "Do you have a shop in Amsterdam?",
			answer:
				"Yes, we have two shops located in the centre of Amsterdam. Our biggest shop is located in the Spuistraat 249 and our other shop is located near the Flower Market on Singel 524.",
		},
		{
			question: "Can I change my email address?",
			answer: `You can change your e-mail address in the "my account" section visible in the right top section of our website when you're logged in. Just change your e-mail address and save your changes.`,
		},
		{
			question: "Can I change my password?",
			answer: `You can change your password in the "my account" section visible in the right top section of our website when you're logged in. Just change your password and save your changes.`,
		},
		{
			question: "Will my package be sent discretely?",
			answer: `Yes all packages are sent discretely. Only your address and the Post NL barcode is visible on the outside.
            `,
		},
	];
	return (
		<section className="w-100">
			
			<div>
				<Accordion defaultActiveKey="0" className="w-100">
					{question?.map((question, index) => {
						return (
							<Accordion.Item eventKey={index}>
								<Accordion.Header>{question.question}</Accordion.Header>
								<Accordion.Body>{question.answer}</Accordion.Body>
							</Accordion.Item>
						);
					})}

					
				</Accordion>
			</div>
		</section>
	);
};

export default FAQ;
