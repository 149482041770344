import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import '../../Server'
import "./PaymentStripe.css";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import useAxiosPost from "../../Utilities/UseAxiosPost/UseAxiosPost";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
// "pk_live_51OoV9yHCVBQlhs008FfMiy4Bet2m6OGUWwfZHXDHO9aWXCOJT2gKaogN69JDceRJHWy7CtOhUQrCM0Euxg1mG04p00S09FqO4d"
const stripePromise = loadStripe(
  "pk_live_51OoV9yHCVBQlhs008FfMiy4Bet2m6OGUWwfZHXDHO9aWXCOJT2gKaogN69JDceRJHWy7CtOhUQrCM0Euxg1mG04p00S09FqO4d"
);

export default function PaymentStripe() {
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  const { postData, responseData } = useAxiosPost();

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        localStorage.setItem('pay',true);
        const response = await postData("/api/payments/handle", {}, true);
        setClientSecret(responseData?.client_secret);
        if (!response.ok) {
          throw new Error("Failed to fetch client secret");
        }
      } catch (error) {
        setError(error.message);
      }
      finally{
        localStorage.setItem('pay',false);
      }
    };

    fetchClientSecret();
  }, []);
  useEffect(() => {
    setClientSecret(responseData?.client_secret);
    console.log(clientSecret);
  }, [responseData, clientSecret]);
  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret ? (
        <>
          <h4>React Stripe and the Payment Element</h4>
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        </>
      ) : (
        <>error client not found</>
      )}
    </>
  );
}

// import React from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import CheckoutForm from "../CheckoutForm/CheckoutForm";

// // Replace the test publishable key with your own test publishable key
// const stripePromise = loadStripe("pk_live_51OoV9yHCVBQlhs008FfMiy4Bet2m6OGUWwfZHXDHO9aWXCOJT2gKaogN69JDceRJHWy7CtOhUQrCM0Euxg1mG04p00S09FqO4d");

// export default function PaymentStripe() {

// useEffect(() => {
//   const fetchClientSecret = async () => {
//     try {
//       const response = await fetch("/create-payment-intent", {
//         method: "POST",
//         body: JSON.stringify({}),
//       });

//       if (!response.ok) {
//         throw new Error("Failed to fetch client secret");
//       }

//       const { clientSecret } = await response.json();
//       setClientSecret(clientSecret);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   fetchClientSecret();
// }, []);
//   const options = {
//     appearance: {
//       theme: "stripe",
//     },
//   };

//   return (
//     <>
//       <h1>React Stripe and the Payment Element</h1>
//       <Elements options={options} stripe={stripePromise}>
//         <CheckoutForm />
//       </Elements>
//     </>
//   );
// }
